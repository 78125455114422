<template>
  <div class="component__wrapper">
    <AboutUs />
  </div>
</template>

<script>
import AboutUs from "@/components/home/below/about-us/AboutUs.vue";

export default {
  components: {
    AboutUs
  }
};
</script>
