<template>
  <!-- <div class="component__wrapper"> -->
  <div class="about-us-wrapper">
    <div class="about-us">
      <div class="left">
        <h3 class="heading">
          Our services are tailored to your property and unique position in the
          market
        </h3>
        <p class="info">
          ATH delivers a full range of graphic design and marketing solutions
          customized for hotels. Our savvy marketing professionals, design gurus
          and technical geniuses invest time to understand your goals, to share
          your story, and drive more revenue.
        </p>
      </div>
      <div class="right">
        <h3 class="heading">We Work With</h3>
        <div class="client-carousel-wrapper">
          <agile class="client-carousel" :options="settings">
            <div
              class="image-wrapper"
              v-for="(client, index) in clients"
              :key="index"
            >
              <img :src="client" alt="" />
            </div>
          </agile>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      settings: {
        dots: false,
        arrows: false,
        autoplay: true,
      },
      clients: [
        // require("@/assets/home/clients/aimbridge-logo-white-250wx175h.png"),
        require('@/assets/home/clients/clients-cambria.png'),
        require('@/assets/home/clients/clients-concord.png'),
        require('@/assets/home/clients/clients-courtyard.png'),
        require('@/assets/home/clients/clients-cp.png'),
        require('@/assets/home/clients/clients-damons.png'),
        require('@/assets/home/clients/clients-dt.png'),
        require('@/assets/home/clients/clients-fairfield.png'),
        require('@/assets/home/clients/clients-gather.png'),
        require('@/assets/home/clients/clients-harrell.png'),
        require('@/assets/home/clients/clients-hgi.png'),
        require('@/assets/home/clients/clients-holidayinn.png'),
        require('@/assets/home/clients/clients-homewood.png'),
        require('@/assets/home/clients/clients-huntington.png'),
        // require("@/assets/home/clients/clients-interstate.png"),
        require('@/assets/home/clients/clients-marriott.png'),
        require('@/assets/home/clients/clients-rena.png'),
        require('@/assets/home/clients/clients-residenceinn.png'),
        require('@/assets/home/clients/Hampton_logo.png'),
      ],
    };
  },
};
</script>

<style scoped>
.about-us-wrapper {
  background-color: rgb(238, 102, 48);
}
.about-us {
  width: 80%;
  margin: 0 auto;
  display: flex;

  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  padding-top: 5rem;
  padding-bottom: 3rem;
}

.left {
  width: 60%;
  font-size: 1.5vw;
  font-family: var(--font-normal);
  color: #314657;
  padding-left: 2rem;
  padding-right: 1rem;
}

.left > .heading {
  font-style: italic;
}

.right {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right > .heading {
  color: #314657;
  font-size: 3vw;
  font-family: var(--font-standout);
  margin: 0;
}
.client-carousel-wrapper {
  width: 50%;
}

.image-wrapper {
  padding: 1rem;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

@media screen and (max-width: 1601px) {
  .about-us {
    width: 90%;
  }
}

@media screen and (max-width: 1366px) {
  .about-us {
    width: 100%;
  }
}

@media screen and (max-width: 1180px) {
  .left {
    flex-basis: 100%;
  }
  .right {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 812px) {
  .about-us {
    padding-top: 2rem;
  }
  .left > .heading {
    font-size: 1.5rem;
  }
  .info {
    font-size: 1.25rem;
  }
  .right > .heading {
    font-size: 3rem;
  }
}
</style>
